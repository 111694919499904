// src/components/About.jsx
import React, { useState, useEffect } from 'react';
import { PortableText } from '@portabletext/react';
import { getAbout } from '../lib/sanity';
import SEO from './SEO';

const About = () => {
  const [about, setAbout] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadAbout = async () => {
      try {
        const data = await getAbout();
        setAbout(data);
      } catch (error) {
        console.error('Error loading about data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    loadAbout();
  }, []);

  if (loading) {
    return (
      <>
        <SEO title="Sobre mí" />
        <div className="min-h-screen pt-20">
          <div className="max-w-3xl mx-auto px-4 py-16">
            <div className="animate-pulse">
              <div className="h-12 bg-gray-100 dark:bg-gray-800 rounded w-1/4 mb-8"></div>
              <div className="space-y-4">
                <div className="h-4 bg-gray-100 dark:bg-gray-800 rounded w-full"></div>
                <div className="h-4 bg-gray-100 dark:bg-gray-800 rounded w-5/6"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <SEO title="Error - Sobre mí" />
        <div className="min-h-screen pt-20">
          <div className="max-w-3xl mx-auto px-4 py-16">
            <h1 className="text-2xl text-red-600 mb-4">Error cargando el contenido</h1>
            <p className="text-gray-600 dark:text-gray-300">{error}</p>
          </div>
        </div>
      </>
    );
  }

  if (!about) {
    return (
      <>
        <SEO title="Contenido no encontrado" />
        <div className="min-h-screen pt-20">
          <div className="max-w-3xl mx-auto px-4 py-16">
            <h1 className="text-2xl mb-4">No se encontró el contenido</h1>
            <p className="text-gray-600 dark:text-gray-300">La información no está disponible en este momento.</p>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <SEO 
        title="Sobre mí"
        description={about.mainContent?.[0]?.children?.[0]?.text || "Conoce más sobre mi experiencia y trayectoria profesional."}
      />
      <div className="min-h-screen pt-20">
        <div className="max-w-3xl mx-auto px-4 py-16">
          <h1 className="text-4xl md:text-5xl font-display mb-12">{about.title}</h1>
          
          <div className="prose lg:prose-lg dark:prose-invert mb-16">
            <PortableText value={about.mainContent} />
          </div>

          {about.experience && about.experience.length > 0 && (
            <div className="mt-16">
              <h2 className="text-2xl md:text-3xl font-display mb-8">Experiencia</h2>
              <div className="space-y-12">
                {about.experience.map((exp, index) => (
                  <div key={index} className="border-b border-gray-200 dark:border-gray-800 pb-8">
                    <h3 className="text-xl md:text-2xl font-display mb-2">{exp.role}</h3>
                    <p className="text-gray-900 dark:text-gray-100 font-medium mb-1">{exp.company}</p>
                    <p className="text-gray-500 dark:text-gray-400 font-mono text-sm mb-4">{exp.period}</p>
                    {exp.description && (
                      <p className="text-gray-600 dark:text-gray-300">{exp.description}</p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default About;