// src/components/home/ProjectGrid.jsx
import React, { useState, useEffect } from 'react';
import { client } from '../../lib/sanity';
import ProjectCard from './ProjectCard';

const ProjectGrid = () => {
  const [featuredProjects, setFeaturedProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadFeaturedProjects = async () => {
      try {
        const query = `
          *[_type == "project" && featured == true] {
            _id,
            title,
            description,
            category,
            isProtected,
            slug,
            mainImage
          }
        `;
        const projects = await client.fetch(query);
        setFeaturedProjects(projects || []);
      } catch (error) {
        console.error('Error loading projects:', error);
        setError('No pude cargar los proyectos');
      } finally {
        setLoading(false);
      }
    };

    loadFeaturedProjects();
  }, []);

  if (loading) {
    return (
      <div className="max-w-[1400px] mx-auto px-4 sm:px-6 md:px-8 py-8 md:py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {[...Array(2)].map((_, index) => (
            <div 
              key={index} 
              className="animate-pulse bg-gray-100 dark:bg-gray-800 rounded-2xl p-8 h-[400px]"
            />
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-[1400px] mx-auto px-4 sm:px-6 md:px-8 py-8 md:py-12">
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  return (
    <div className="max-w-[1400px] mx-auto px-4 sm:px-6 md:px-8 py-8 md:py-12">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {featuredProjects.map(project => (
          <ProjectCard key={project._id} project={project} />
        ))}
      </div>
    </div>
  );
};

export default ProjectGrid;