// src/lib/sanity.js
import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

const projectId = import.meta.env.VITE_SANITY_PROJECT_ID;
const dataset = import.meta.env.VITE_SANITY_DATASET || 'production';

if (!projectId) {
  console.error('Missing VITE_SANITY_PROJECT_ID in environment variables');
}

export const client = createClient({
  projectId,
  dataset,
  useCdn: true,
  apiVersion: '2024-01-10'
});

// Configurar el builder de URLs para imágenes
const builder = imageUrlBuilder(client);

// Función helper para construir URLs de imagen
export const urlFor = (source) => {
  if (!source?.asset?._ref) {
    console.warn('Invalid image source provided to urlFor:', source);
    return '';
  }
  return builder.image(source);
};

// Consultas existentes
export const getAbout = async () => {
  try {
    return await client.fetch(`
      *[_type == "about"][0] {
        title,
        mainContent,
        experience[] {
          role,
          company,
          period,
          description
        }
      }
    `);
  } catch (error) {
    console.error('Error fetching about data:', error);
    throw error;
  }
};

export const getAllPosts = async () => {
  try {
    return await client.fetch(`
      *[_type == "post"] | order(publishedAt desc) {
        _id,
        title,
        permalink,
        publishedAt,
        mainImage,
        author->{
          name,
          image
        },
        categories[]->{
          title
        }
      }
    `);
  } catch (error) {
    console.error('Error fetching posts:', error);
    throw error;
  }
};

export const getPost = async (permalink) => {
  if (!permalink) throw new Error('Permalink is required');
  
  try {
    const post = await client.fetch(`
      *[_type == "post" && permalink.current == $permalink][0] {
        _id,
        title,
        permalink,
        body,
        publishedAt,
        mainImage,
        author->{
          name,
          image
        },
        categories[]->{
          _id,
          title
        }
      }
    `, { permalink });

    if (!post) {
      throw new Error('Post not found');
    }

    return post;
  } catch (error) {
    console.error('Error fetching post:', error);
    throw error;
  }
};

// Nuevas consultas para el portafolio
export const getFeaturedProjects = async () => {
  try {
    return await client.fetch(`
      *[_type == "project" && featured == true] | order(title asc) {
        _id,
        title,
        description,
        category,
        mainImage,
        slug,
        isProtected,
        externalLink,
        body
      }
    `);
  } catch (error) {
    console.error('Error fetching featured projects:', error);
    throw error;
  }
};

export const getProtectedProjects = async () => {
  try {
    return await client.fetch(`
      *[_type == "project" && isProtected == true] | order(title asc) {
        _id,
        title,
        description,
        category,
        mainImage,
        slug,
        body,
        externalLink
      }
    `);
  } catch (error) {
    console.error('Error fetching protected projects:', error);
    throw error;
  }
};

export const getProject = async (slug) => {
  if (!slug) throw new Error('Slug is required');
  
  try {
    const project = await client.fetch(`
      *[_type == "project" && slug.current == $slug][0] {
        _id,
        title,
        description,
        category,
        mainImage,
        slug,
        body,
        externalLink,
        isProtected
      }
    `, { slug });

    if (!project) {
      throw new Error('Project not found');
    }

    return project;
  } catch (error) {
    console.error('Error fetching project:', error);
    throw error;
  }
};