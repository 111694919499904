// src/components/blog/BlogPost.jsx
import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { PortableText } from '@portabletext/react';
import { client, urlFor } from '../../lib/sanity';
import { ArrowLeft, Linkedin, MessageCircle } from 'lucide-react';
import portableTextComponents from '../shared/PortableTextComponents';
import SEO from '../SEO';

const BlogPost = () => {
  const { permalink } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, [permalink]);

  // Helper para URLs de imágenes
  const getImageUrl = (image) => {
    try {
      if (image?.asset?.url) return image.asset.url;
      if (image?.asset?._ref) return urlFor(image).url();
      return null;
    } catch (err) {
      console.warn('Error getting image URL:', err);
      return image?.asset?.url || null;
    }
  };

  // Cargar post
  useEffect(() => {
    const loadPost = async () => {
      try {
        const result = await client.fetch(`
          *[_type == "post" && permalink.current == $permalink][0] {
            _id,
            title,
            publishedAt,
            body[] {
              ...,
              asset->,
              children[] {
                ...,
                marks,
                text,
                _type == "span" => @
              }
            },
            mainImage {
              asset-> {
                _id,
                _type,
                url,
                metadata {
                  dimensions
                }
              }
            },
            "estimatedReadingTime": round(length(pt::text(body)) / 5 / 180),
            categories[]-> {
              _id,
              title,
              description
            },
            author-> {
              name,
              title,
              bio,
              image {
                asset-> {
                  _id,
                  _type,
                  url
                }
              }
            }
          }
        `, { permalink });

        if (!result) throw new Error('Artículo no encontrado');
        setPost(result);
      } catch (err) {
        console.error('Error loading post:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadPost();
  }, [permalink]);

  if (loading) {
    return (
      <div className="min-h-screen pt-20 blog-background">
        <div className="animate-pulse max-w-5xl mx-auto px-6">
          <div className="h-[400px] bg-gray-200 dark:bg-gray-800 w-full mb-12 rounded-xl" />
          <div className="space-y-4 max-w-3xl mx-auto">
            <div className="h-12 bg-gray-200 dark:bg-gray-800 rounded w-3/4" />
            <div className="h-8 bg-gray-200 dark:bg-gray-800 rounded w-1/2" />
            <div className="h-4 bg-gray-200 dark:bg-gray-800 rounded w-full" />
          </div>
        </div>
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="min-h-screen pt-20 blog-background">
        <div className="max-w-5xl mx-auto px-6 py-16">
          <h1 className="text-2xl font-display text-red-600 dark:text-red-400 mb-4">
            {error || 'Artículo no encontrado'}
          </h1>
          <button
            onClick={() => navigate(-1)}
            className="blog-nav-link"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Volver al blog
          </button>
        </div>
      </div>
    );
  }

  const mainImageUrl = getImageUrl(post.mainImage);
  const shareUrl = `https://starkji.net/blog/${permalink}`;

  return (
    <>
      <SEO
        title={post.title}
        type="article"
        publishedAt={post.publishedAt}
        image={mainImageUrl}
        author={post.author?.name}
      />

      <article className="min-h-screen pt-20 blog-background">
        {/* Hero Image */}
        {mainImageUrl && (
          <div className="blog-hero-image">
            <img
              src={mainImageUrl}
              alt={post.title}
            />
          </div>
        )}

        <div className="max-w-5xl mx-auto px-6">
          {/* Categories */}
          {post.categories?.length > 0 && (
            <div className="blog-category-wrapper">
              {post.categories.map(category => (
                <span
                  key={category._id}
                  className="blog-category"
                  title={category.description || ''}
                >
                  {category.title}
                </span>
              ))}
            </div>
          )}

          {/* Title */}
          <h1 className="blog-title">{post.title}</h1>

          {/* Meta info */}
          <div className="blog-meta">
            <div className="blog-meta-item">
              <dt className="blog-meta-label">Publicado originalmente el</dt>
              <dd className="blog-meta-value">
                {new Date(post.publishedAt).toLocaleDateString('es-ES', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </dd>
            </div>
            
            <div className="blog-meta-item">
              <dt className="blog-meta-label">Tiempo de lectura</dt>
              <dd className="blog-meta-value">{post.estimatedReadingTime} minutos</dd>
            </div>
            
            <div className="blog-meta-item">
              <dt className="blog-meta-label">Compartir</dt>
              <dd className="flex gap-3">
                <a
                  href={`https://x.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(post.title)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="blog-share-button font-mono"
                  aria-label="Compartir en X"
                >
                  𝕏
                </a>

                <a
                  href={`https://threads.net/intent/post?text=${encodeURIComponent(`${post.title} ${shareUrl}`)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="blog-share-button"
                  aria-label="Compartir en Threads"
                >
                  <MessageCircle size={16} />
                </a>

                <a
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="blog-share-button"
                  aria-label="Compartir en LinkedIn"
                >
                  <Linkedin size={16} />
                </a>
              </dd>
            </div>
          </div>

          {/* Article Content */}
          <div className="max-w-3xl mx-auto py-12 md:py-20">
            {post.body && (
              <div className="blog-content">
                <PortableText
                  value={post.body}
                  components={portableTextComponents}
                />
              </div>
            )}

            {/* Author Bio */}
            {post.author && (
              <div className="blog-author-bio">
                <div className="blog-author-header">
                  {post.author.image && getImageUrl(post.author.image) && (
                    <img
                      src={getImageUrl(post.author.image)}
                      alt={post.author.name}
                      className="blog-author-image"
                    />
                  )}
                  <div>
                    <h2 className="blog-author-name">{post.author.name}</h2>
                    {post.author.title && (
                      <p className="blog-author-title">{post.author.title}</p>
                    )}
                  </div>
                </div>
                {post.author.bio && (
                  <div className="mt-4 prose prose-sm dark:prose-invert">
                    <PortableText
                      value={post.author.bio}
                      components={portableTextComponents}
                    />
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Navigation */}
<div className="max-w-3xl mx-auto flex justify-between items-center py-8 mt-8">
  <Link to="/blog" className="blog-nav-link group">
    <ArrowLeft className="w-4 h-4 mr-2 transform transition-transform 
                       group-hover:-translate-x-1" />
    <span>Volver al blog</span>
  </Link>
</div>
        </div>
      </article>
    </>
  );
};

export default BlogPost;