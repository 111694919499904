// src/components/portfolio/PortfolioPage.jsx
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'sonner';
import SEO from '../SEO';
import PortfolioGrid from './PortfolioGrid';
import PortfolioLogin from './PortfolioLogin';
import { isPortfolioSessionValid } from '../../lib/portfolio';

const PortfolioPage = () => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);

  // Verificar el estado de autenticación al cargar
  useEffect(() => {
    const checkAuth = () => {
      const hasPassword = localStorage.getItem('portfolio-auth') === 'true';
      const hasValidSession = isPortfolioSessionValid();
      setIsAuthenticated(hasPassword || hasValidSession);
    };

    checkAuth();
    // Verificar cambios en el sessionStorage
    window.addEventListener('storage', checkAuth);
    return () => window.removeEventListener('storage', checkAuth);
  }, []);

  // Manejar redirecciones con state
  useEffect(() => {
    if (location.state?.showAccessForm) {
      setShowLoginForm(true);
    }
  }, [location]);

  const handleAuthSuccess = () => {
    setIsAuthenticated(true);
    setShowLoginForm(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('portfolio-auth');
    localStorage.removeItem('portfolio-session');
    setIsAuthenticated(false);
    toast.success('✅ Cerraste sesión. ¡Gracias por venir! 👋');
  };

  return (
    <>
      <SEO 
        title={isAuthenticated ? "Portafolio Completo" : "Portafolio"}
        description="Casos de estudio y proyectos seleccionados."
        noindex={true}
      />
      <div className="min-h-screen pt-20">
        <div className="max-w-[1400px] mx-auto px-6 py-16">
          <div className="flex items-end justify-between mb-12 md:mb-16">
            <div>
              <span className="text-sm font-mono text-gray-500 dark:text-gray-400 mb-4 block">
                Casos de estudio y proyectos seleccionados
              </span>
              <h1 className="text-4xl md:text-5xl lg:text-6xl font-display leading-tight">
                Portafolio
              </h1>
              <p className="mt-4 text-lg text-gray-600 dark:text-gray-300 max-w-2xl">
                Una pequeña selección de mi travesía profesional en estrategia digital, medios de comunicación y diseño de experiencia.
                {!isAuthenticated && !showLoginForm && (
                  <>
                    {' '}
                    <button 
                      onClick={() => setShowLoginForm(true)}
                      className="text-black dark:text-white hover:underline inline-flex items-center"
                    >
                      <span>¿No tienes acceso? Solicítalo aquí</span>
                      <svg className="w-4 h-4 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                      </svg>
                    </button>
                  </>
                )}
              </p>
            </div>
            {isAuthenticated && (
              <div className="flex flex-col items-end space-y-2
                sm:flex-row sm:items-center sm:space-x-4 sm:space-y-0">
                <span className="text-sm text-gray-500 dark:text-gray-400 font-mono">
                  {localStorage.getItem('portfolio-session') ? (
                    '👋 ¡Hola! Tu acceso es por 12 horas'
                  ) : (
                    '🏡 ¡Hola! Tu acceso es con contraseña maestra'
                  )}
                </span>
                <button
                  onClick={handleLogout}
                  className="text-sm text-gray-500 hover:text-black dark:hover:text-white transition-colors font-mono"
                >
                  <strong>Cerrar sesión</strong> 
                </button>
              </div>
            )}
          </div>

          {showLoginForm && !isAuthenticated ? (
            <PortfolioLogin 
              onSuccess={handleAuthSuccess}
            />
          ) : (
            <PortfolioGrid 
              isAuthenticated={isAuthenticated}
              onAuthRequest={() => setShowLoginForm(true)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PortfolioPage;