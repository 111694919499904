// src/components/layout/Footer.jsx
import React from 'react';
import { 
  Linkedin, 
  Newspaper, 
  BookText, 
  AtSign, 
  Github, 
  FileText,
  BookOpen,
  Music,
  Youtube,
  MessageCircle,
  Facebook,
  PenTool,
  Mail
} from 'lucide-react';

const Footer = () => {
  // Función para ofuscar el email
  const getEmail = () => {
    const parts = ['hola', '@', 'starkji', '.', 'net'];
    return parts.join('');
  };

  const socialLinks = [
    { 
      name: 'LinkedIn', 
      icon: Linkedin, 
      url: 'https://linkedin.com/in/jistark',
      description: 'Mi perfil profesional en LinkedIn'
    },
    { 
      name: 'Newsletter', 
      icon: Newspaper, 
      url: 'https://futurosnews.beehiiv.com/subscribe',
      description: 'Futuros, mi newsletter sobre cultura digital y tecnología'
    },
    { 
      name: 'Substack', 
      icon: BookText, 
      url: 'https://futuros.substack.com',
      description: 'El archivo de Futuros en Substack. Ya no uso esa plataforma, por lo que te recomiendo suscribirte en Beehiiv'
    },
    { 
      name: 'Bluesky', 
      icon: AtSign, 
      url: 'https://bsky.app/profile/stark.soy',
      description: 'Mi perfil en Bluesky, el nuevo Twitter sin Elon'
    },
    { 
      name: 'Threads', 
      icon: MessageCircle, 
      url: 'https://www.threads.net/@jistark',
      description: 'Posts que le aparecen a un cuarto de mis seguidores ocho días después. Gracias, Mosseri'
    },
    { 
      name: 'GitHub', 
      icon: Github, 
      url: 'https://github.com/jistark',
      description: 'Repositorios y proyectos de código'
    },
    { 
      name: 'Tumblr', 
      icon: PenTool, 
      url: 'https://www.tumblr.com/jistark',
      description: '¿Puedes creer que tengo Tumblr desde 2007?'
    },
    { 
      name: 'Facebook', 
      icon: Facebook, 
      url: 'https://www.facebook.com/joseignaciostark',
      description: 'Mira, como que alguna vez me hice una página en Facebook…'
    },
    { 
      name: 'Goodreads', 
      icon: BookOpen, 
      url: 'https://www.goodreads.com/user/show/183731490-ji-stark',
      description: 'Los libros que leo y recomiendo'
    },
    { 
      name: 'Spotify', 
      icon: Music, 
      url: 'https://open.spotify.com/user/jistarkv',
      description: 'Mis playlists y descubrimientos musicales'
    },
    { 
      name: 'YouTube', 
      icon: Youtube, 
      url: 'https://youtube.com/channel/jistark',
      description: 'Pocos videos pero muchos favs'
    }
  ];

  return (
    <footer className="w-full border-t border-gray-200 dark:border-gray-800 bg-white/50 dark:bg-gray-950/50 backdrop-blur-sm">
      <div className="max-w-[1400px] mx-auto px-6 py-6">
        <div className="flex flex-col md:flex-row items-center justify-between gap-4 md:gap-8">
          {/* Copyright y CV */}
          <div className="flex items-center space-x-4 text-sm text-gray-500 dark:text-gray-400">
            <span>2007-{new Date().getFullYear()}, José Ignacio Stark. Algunos derechos reservados.</span>
            <a 
              href="/cv.pdf" 
              className="inline-flex items-center hover:text-black dark:hover:text-white transition-colors"
              target="_blank"
              rel="noopener noreferrer"
              title="Descarga mi CV en formato PDF"
            >
              <FileText className="w-4 h-4 mr-1" />
              <span>CV</span>
            </a>
            <a
              href={`mailto:${getEmail()}`}
              className="inline-flex items-center hover:text-black dark:hover:text-white transition-colors"
              rel="nofollow"
              title="Envíame un correo electrónico"
            >
              <Mail className="w-4 h-4 mr-1" />
              <span className="sr-only">Contacto</span>
            </a>
          </div>

          {/* Social Links */}
          <div className="flex flex-wrap items-center gap-4">
            {socialLinks.map(({ name, icon: Icon, url, description }) => (
              <a
                key={name}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-black dark:hover:text-white transition-colors"
                aria-label={description}
                title={description}
              >
                <Icon className="w-4 h-4" />
                <span className="sr-only">{description}</span>
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;