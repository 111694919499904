// src/components/portfolio/PortfolioLogin.jsx
import React, { useState, useEffect } from 'react';
import { toast } from 'sonner';
import { useLocation } from 'react-router-dom';
import { isPortfolioSessionValid } from '../../lib/portfolio';
import AccessRequestForm from './AccessRequestForm';

const PortfolioLogin = ({ onSuccess }) => {
  const location = useLocation();
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAccessForm, setShowAccessForm] = useState(false);

  // Verificar si hay una sesión válida al cargar
  useEffect(() => {
    if (isPortfolioSessionValid()) {
      onSuccess();
    }
  }, [onSuccess]);

  // Verificar si venimos de una redirección que solicita mostrar el formulario
  useEffect(() => {
    if (location.state?.showAccessForm) {
      setShowAccessForm(true);
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      if (password === import.meta.env.VITE_PORTFOLIO_PASSWORD) {
        localStorage.setItem('portfolio-auth', 'true');
        onSuccess();
        toast.success('Eres de la casa 🏠', {
          description: 'Te autenticaste con la contraseña maestra.'
        });
      } else {
        setError('La contraseña que ingresaste no es correcta.');
        setPassword('');
      }
    } catch (err) {
      setError('No pude verificar la contraseña que ingresaste. Por favor, inténtalo más tarde.');
      console.error('Error en verificación de contraseña:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleAccessRequestSuccess = () => {
    toast.success('Tu acceso va en camino 💌', {
      description: 'Revisa tu correo y encontrarás el enlace de acceso.'
    });
    setShowAccessForm(false);
  };

  if (showAccessForm) {
    return (
      <AccessRequestForm 
        onClose={() => setShowAccessForm(false)}
        onSuccess={handleAccessRequestSuccess}
      />
    );
  }

  return (
    <div className="max-w-md mx-auto bg-white dark:bg-gray-900 rounded-2xl p-8">
      <div className="mb-8">
        <h2 className="text-3xl font-display mb-4">Acceso restringido</h2>
        <div className="prose dark:prose-invert mb-6">
          <p className="text-gray-600 dark:text-gray-300">
            Para ver mi portafolio, tienes dos opciones:
          </p>
          <ol className="text-sm text-gray-600 dark:text-gray-300 list-decimal pl-4 space-y-2">
            <li>
              <strong>Pedirme un enlace de acceso temporal</strong> que recibirás por correo
              (recomendado);
            </li>
            <li>
              <strong>Usar una contraseña</strong>, si ya te la entregué.
            </li>
          </ol>
        </div>

        <div className="bg-gray-50 dark:bg-gray-800 rounded-lg p-4">
          <button 
            onClick={() => setShowAccessForm(true)}
            className="w-full text-left text-black dark:text-white hover:opacity-75 transition-opacity"
          >
            <p className="font-medium mb-1">
              Pide tu enlace de acceso →
            </p>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Te enviaré un <em>magic link</em> 🪄 a tu correo, válido por 12 horas.
            </p>
          </button>
        </div>
      </div>

      <div className="relative my-8">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-200 dark:border-gray-800"></div>
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-white dark:bg-gray-900 text-gray-500 dark:text-gray-400">
            o
          </span>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label 
            htmlFor="password"
            className="block text-sm font-mono text-gray-700 dark:text-gray-300 mb-2"
          >
            Ingresa la contraseña del portafolio:
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700
                     focus:ring-2 focus:ring-black dark:focus:ring-white focus:border-transparent
                     bg-white dark:bg-gray-800 text-black dark:text-white"
            placeholder="••••••••"
          />
        </div>

        {error && (
          <p className="text-red-500 dark:text-red-400 text-sm font-mono">
            {error}
          </p>
        )}

        <button
          type="submit"
          disabled={loading || !password.trim()}
          className="w-full bg-black dark:bg-white text-white dark:text-black py-3 rounded-lg
                   hover:bg-gray-800 dark:hover:bg-gray-100 transition-colors font-mono
                   disabled:opacity-50 disabled:cursor-not-allowed
                   focus:outline-none focus:ring-2 focus:ring-black dark:focus:ring-white"
        >
          {loading ? 'Verificando...' : 'Ingresar'}
        </button>
      </form>

      <p className="mt-6 text-xs text-gray-500 dark:text-gray-400">
        Si tienes problemas para acceder, escríbeme a {' '}
        <a 
          href="mailto:web@starkji.net?subject=No puedo acceder a tu portafolio, JI!" 
          className="text-black dark:text-white hover:underline"
        >
          web@starkji.net
        </a>
      </p>
    </div>
  );
};

export default PortfolioLogin;