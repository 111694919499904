// src/components/shared/SEO.jsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const SEO = ({ 
  title, 
  description, 
  image, 
  type = 'website',
  author = 'José Ignacio Stark',
  publishedAt,
  noindex = false
}) => {
  const location = useLocation();
  const siteUrl = import.meta.env.VITE_SITE_URL || 'https://starkji.net';
  const siteName = 'José Ignacio Stark';
  const defaultDescription = 'Sitio Web y portafolio de José Ignacio (JI) Stark.';
  const defaultImage = `${siteUrl}/social-share.jpg`;

  const pageTitle = title ? `${title} | ${siteName}` : siteName;
  const pageDescription = description || defaultDescription;
  const pageImage = image || defaultImage;
  const canonicalUrl = `${siteUrl}${location.pathname}`;

  return (
    <Helmet>
      {/* Básicos */}
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta name="theme-color" content="#4285f4" />
      
      {/* Robots */}
      <meta 
        name="robots" 
        content={noindex ? 'noindex,nofollow' : 'index,follow'} 
      />
      <meta 
        name="googlebot" 
        content={noindex ? 'noindex,nofollow' : 'index,follow'} 
      />

      {/* Canonical */}
      <link rel="canonical" href={canonicalUrl} />

      {/* Open Graph */}
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:image" content={pageImage} />
      <meta property="og:image:alt" content={pageTitle} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:locale" content="es_CL" />
      
      {/* Si es un artículo */}
      {type === 'article' && (
        <>
          <meta property="article:author" content={author} />
          {publishedAt && (
            <meta property="article:published_time" content={publishedAt} />
          )}
        </>
      )}

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:image" content={pageImage} />
      <meta name="twitter:dnt" content="on" />

      {/* Pinterest */}
      <meta name="pinterest" content="nopin" description="Lo siento, no se pueden guardar imágenes de mi sitio." />

      {/* Idioma alternativo */}
      <link rel="alternate" href={siteUrl} hreflang="es" />
    </Helmet>
  );
};

export default SEO;