// src/pages/Home.jsx
import React from 'react';
import SEO from '../SEO';
import Hero from './Hero';
import ProjectGrid from './ProjectGrid';

const Home = () => {
  return (
    <>
      <SEO 
        description="Sitio Web y portafolio de José Ignacio (JI) Stark."
      />
      <div className="relative min-h-screen">
        {/* Gradient Background */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-950"></div>
        </div>

        {/* Main Content */}
        <div className="relative">
          <Hero />
          <ProjectGrid />
        </div>
      </div>
    </>
  );
};

export default Home;