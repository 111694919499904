// src/components/portfolio/PortfolioGrid.jsx
import React, { useState, useEffect } from 'react';
import { getPortfolioProjects } from '../../lib/portfolio';
import ProjectCard from './ProjectCard';
import AccessRequestForm from './AccessRequestForm';
import { toast } from 'sonner';

const PortfolioGrid = ({ isAuthenticated }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAccessForm, setShowAccessForm] = useState(false);

  useEffect(() => {
    const loadProjects = async () => {
      try {
        const result = await getPortfolioProjects();
        setProjects(result || []);
      } catch (error) {
        console.error('Error loading projects:', error);
        setError('Error al cargar los proyectos');
      } finally {
        setLoading(false);
      }
    };

    loadProjects();
  }, []);

  const handleRequestAccess = () => {
    setShowAccessForm(true);
  };

  const handleAccessSuccess = () => {
    toast.success('Muchas gracias', {
      description: 'Te contactaré a la brevedad con los detalles para acceder.'
    });
  };

  if (loading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {[...Array(4)].map((_, index) => (
          <div 
            key={index} 
            className="animate-pulse bg-gray-100 dark:bg-gray-800 rounded-2xl p-8 h-[500px]"
          />
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 dark:bg-red-900/10 rounded-2xl p-8">
        <p className="text-red-600 dark:text-red-400">{error}</p>
        <button 
          onClick={() => window.location.reload()} 
          className="mt-4 text-sm hover:underline"
        >
          Intentar de nuevo
        </button>
      </div>
    );
  }

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {projects.map(project => (
          <ProjectCard
            key={project._id}
            project={project}
            isAuthenticated={isAuthenticated}
            onRequestAccess={handleRequestAccess}
          />
        ))}
      </div>

      {showAccessForm && (
        <AccessRequestForm
          onClose={() => setShowAccessForm(false)}
          onSuccess={handleAccessSuccess}
        />
      )}
    </>
  );
};

export default PortfolioGrid;