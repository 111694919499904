// src/components/shared/PortableTextComponents.jsx
import React from 'react';
import { urlFor } from '../../lib/sanity';

// Helper para obtener URL de imagen de forma segura
const getImageUrl = (image) => {
  try {
    if (image?.asset?.url) {
      return image.asset.url;
    }
    if (image?.asset?._ref) {
      return urlFor(image).url();
    }
    return null;
  } catch (err) {
    console.warn('Error getting image URL:', err);
    return image?.asset?.url || null;
  }
};

const PortableTextComponents = {
  types: {
    block: ({value}) => {
      // Si no hay children o no es un array, retornar null
      if (!Array.isArray(value?.children)) {
        console.warn('Invalid block value:', value);
        return null;
      }

      const style = value.style || 'normal';

      // Procesar el contenido del bloque de manera segura
      const content = value.children.map((child, i) => {
        if (!child || typeof child !== 'object') return null;
        
        if (child._type !== 'span') {
          console.warn('Unexpected child type:', child._type);
          return null;
        }

        let text = child.text || '';
        let result = text;

        // Aplicar marks de manera segura
        if (Array.isArray(child.marks)) {
          child.marks.forEach(mark => {
            switch (mark) {
              case 'strong':
                result = <strong key={`${i}-strong`}>{result}</strong>;
                break;
              case 'em':
                result = <em key={`${i}-em`}>{result}</em>;
                break;
              case 'code':
                result = <code key={`${i}-code`} className="bg-gray-100 dark:bg-gray-800 rounded px-1 py-0.5 font-mono text-sm">{result}</code>;
                break;
            }
          });
        }

        return <React.Fragment key={child._key || i}>{result}</React.Fragment>;
      });

      // Renderizar el bloque según su estilo
      switch (style) {
        case 'h1':
          return <h1 className="text-4xl md:text-5xl font-display mb-6">{content}</h1>;
        case 'h2':
          return <h2 className="text-3xl md:text-4xl font-display mb-4">{content}</h2>;
        case 'h3':
          return <h3 className="text-2xl md:text-3xl font-display mb-4">{content}</h3>;
        case 'h4':
          return <h4 className="text-xl md:text-2xl font-display mb-3">{content}</h4>;
        case 'blockquote':
          return (
            <blockquote className="border-l-4 border-gray-200 dark:border-gray-800 pl-4 italic my-6">
              {content}
            </blockquote>
          );
        default:
          if (value.listItem === 'bullet') {
            return <li className="text-gray-600 dark:text-gray-300">{content}</li>;
          }
          // Envolver el contenido en un fragmento para evitar problemas con marcas anidadas
          return <p className="mb-4 text-gray-600 dark:text-gray-300">{content}</p>;
      }
    },

    image: ({value}) => {
      const imageUrl = getImageUrl(value);
      if (!imageUrl) return null;

      return (
        <figure className="my-8">
          <img
            src={imageUrl}
            alt={value.alt || ''}
            className="rounded-lg w-full"
            loading="lazy"
          />
          {value.caption && (
            <figcaption className="mt-2 text-sm text-gray-500 dark:text-gray-400 text-center">
              {value.caption}
            </figcaption>
          )}
        </figure>
      );
    }
  },

  list: {
    bullet: ({children}) => (
      <ul className="list-disc pl-6 mb-4 space-y-2">
        {children}
      </ul>
    ),
    number: ({children}) => (
      <ol className="list-decimal pl-6 mb-4 space-y-2">
        {children}
      </ol>
    )
  },

  marks: {
    link: ({children, value}) => {
      const rel = value.href.startsWith('/') ? undefined : 'noreferrer noopener';
      return (
        <a 
          href={value.href}
          rel={rel}
          className="text-blue-600 dark:text-blue-400 hover:underline"
          target={value.blank ? '_blank' : undefined}
        >
          {children}
        </a>
      );
    }
  }
};

export default PortableTextComponents;