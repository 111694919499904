// src/components/Analytics.jsx
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TRACKING_ID = 'G-7Z28ZMPCWJ';

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Inicialización de GA
    const loadGoogleAnalytics = () => {
      const script1 = document.createElement('script');
      script1.src = `https://www.googletagmanager.com/gtag/js?id=${TRACKING_ID}`;
      script1.async = true;
      document.head.appendChild(script1);

      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', TRACKING_ID);

      window.gtag = gtag;
    };

    loadGoogleAnalytics();
  }, []);

  // Tracking de cambios de página
  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search
      });
    }
  }, [location]);

  return null;
};

export default Analytics;