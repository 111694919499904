// src/components/blog/BlogPreview.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { urlFor } from '../../lib/sanity';

const BlogPreview = ({ post }) => {
  return (
    <article className="py-12 first:pt-0 group">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        {/* Imagen y Categorías */}
        <div className="md:col-span-1">
          {post.mainImage && (
            <div className="aspect-square overflow-hidden rounded-lg mb-4">
              <img
                src={urlFor(post.mainImage)
                  .width(400)
                  .height(400)
                  .fit('crop')
                  .crop('center')
                  .url()}
                alt=""
                className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
              />
            </div>
          )}
          {post.categories?.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {post.categories.map(category => (
                <span 
                  key={category.title}
                  className="text-xs font-mono text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                >
                  {category.title}
                </span>
              ))}
            </div>
          )}
        </div>

        {/* Contenido */}
        <div className="md:col-span-3">
          <h2 className="text-2xl md:text-3xl font-display mb-4 group-hover:text-gray-600 dark:group-hover:text-gray-300 transition-colors">
            <Link to={`/blog/${post.permalink?.current}`}>
              {post.title}
            </Link>
          </h2>
          
          {post.publishedAt && (
            <time 
              dateTime={post.publishedAt} 
              className="block text-gray-500 dark:text-gray-400 mb-4 font-mono text-sm"
            >
              {new Date(post.publishedAt).toLocaleDateString('es-ES', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </time>
          )}
          
          {post.excerpt && (
            <p className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed">
              {post.excerpt}
            </p>
          )}
          
          <div className="mt-4">
            <Link 
              to={`/blog/${post.permalink?.current}`}
              className="inline-flex items-center text-gray-600 dark:text-gray-300 hover:text-black dark:hover:text-white transition-colors text-sm font-mono group/link"
            >
              Leer más
              <span className="ml-2 transform transition-transform group-hover/link:translate-x-1">→</span>
            </Link>
          </div>
        </div>
      </div>
    </article>
  );
};

export default BlogPreview;