// src/components/portfolio/ProjectCard.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { urlFor } from '../../lib/sanity';
import { Lock } from 'lucide-react';

const ProjectCard = ({ project, isAuthenticated = false, onRequestAccess }) => {
  if (!project) return null;

  const isProtected = project.isProtected && !isAuthenticated;

  const CardWrapper = ({ children }) => (
    <article 
      className={`relative bg-white dark:bg-gray-900 rounded-none md:rounded-2xl overflow-hidden 
                flex flex-col transition-all duration-300 group
                ${isProtected ? 'cursor-default' : ''}`}
    >
      {children}
      
      {isProtected && (
        <div className="absolute inset-0 bg-gray-100/80 dark:bg-gray-900/80 backdrop-blur-sm 
                      flex flex-col items-center justify-center p-6 text-center
                      opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <Lock className="w-6 h-6 mb-4 text-gray-900 dark:text-gray-100" />
          <h3 className="text-xl font-display text-gray-900 dark:text-gray-100 mb-2">
            Acceso restringido
          </h3>
          <p className="text-sm text-gray-600 dark:text-gray-400 mb-4 max-w-xs">
            Lo siento, necesitas autorización para visualizar este proyecto en detalle.
          </p>
          <button
            onClick={onRequestAccess}
            className="inline-flex items-center px-4 py-2 text-sm font-mono
                     bg-black dark:bg-white text-white dark:text-black
                     hover:bg-gray-800 dark:hover:bg-gray-100
                     transition-colors rounded-lg"
          >
            Pide una contraseña
          </button>
        </div>
      )}
    </article>
  );

  // Contenido de la imagen con su respectivo link
  const ProjectContent = !isProtected ? (
    <Link
      to={`/projects/${project.slug.current}`}
      className="relative w-full h-[200px] md:h-[300px] overflow-hidden"
    >
      <img
        src={urlFor(project.mainImage)
          .width(800)
          .height(600)
          .fit('crop')
          .url()}
        alt={project.title}
        className="w-full h-full object-cover transition-all duration-500 group-hover:scale-105"
      />
      <div className="absolute inset-0 bg-black/0 group-hover:bg-black/10 transition-colors duration-500" />
    </Link>
  ) : (
    <div className="relative w-full h-[200px] md:h-[300px] overflow-hidden">
      <img
        src={urlFor(project.mainImage)
          .width(800)
          .height(600)
          .fit('crop')
          .url()}
        alt="Proyecto protegido"
        className="w-full h-full object-cover blur-sm grayscale"
      />
    </div>
  );

  return (
    <CardWrapper>
      {/* Imagen destacada */}
      {project.mainImage && ProjectContent}

      {/* Contenido */}
      <div className="p-4 sm:p-6 md:p-8 flex flex-col flex-grow">
        {project.category && (
          <span className="inline-block text-xs font-mono text-gray-500 dark:text-gray-400 mb-4 md:mb-6">
            {project.category}
          </span>
        )}

        <div className="mb-6 md:mb-8">
          <h2 className={`text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-display leading-tight mb-4 md:mb-6 
                       ${isProtected ? 'text-gray-400 dark:text-gray-600' : 'text-gray-900 dark:text-white'}`}>
            {isProtected ? project.title.replace(/[a-zA-Z]/g, '•') : project.title}
          </h2>
          {project.description && (
            <p className={`text-base md:text-lg leading-relaxed
                        ${isProtected ? 'text-gray-400 dark:text-gray-600' : 'text-gray-600 dark:text-gray-300'}`}>
              {isProtected ? '••••• ••••• ••••• •••••' : project.description}
            </p>
          )}
        </div>

        {!isProtected && (
          <div className="mt-auto font-mono">
            <Link 
              to={`/projects/${project.slug.current}`}
              className="inline-flex items-center text-gray-900 dark:text-white hover:opacity-70 transition-opacity text-sm"
              aria-label={`Ver más detalles sobre ${project.title}`}
            >
              <span className="border-b border-current pb-0.5">
                Ver proyecto →
              </span>
            </Link>
          </div>
        )}
      </div>
    </CardWrapper>
  );
};

export default ProjectCard;