// src/lib/supabase.js
import { createClient } from '@supabase/supabase-js';

// Función para obtener las variables de entorno de manera segura
const getEnvVars = () => {
  // Solo usar import.meta.env para el cliente
  const supabaseUrl = import.meta?.env?.VITE_SUPABASE_URL;
  const supabaseAnonKey = import.meta?.env?.VITE_SUPABASE_ANON_KEY;

  if (!supabaseUrl || !supabaseAnonKey) {
    console.warn('Variables de entorno de Supabase no encontradas');
  }

  return {
    url: supabaseUrl || '',
    key: supabaseAnonKey || ''
  };
};

// Crear cliente de Supabase
const createSupabaseClient = () => {
  const { url, key } = getEnvVars();

  if (!url || !key) {
    return null;
  }

  try {
    return createClient(url, key, {
      auth: {
        autoRefreshToken: false,
        persistSession: false
      }
    });
  } catch (error) {
    console.error('Error al crear cliente Supabase:', error);
    return null;
  }
};

// Exportar una única instancia del cliente
export const supabase = createSupabaseClient();

// Helper para verificar si supabase está inicializado
export const isSupabaseInitialized = () => !!supabase;