// src/components/home/ProjectCard.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { urlFor } from '../../lib/sanity';

const ProjectCard = ({ project }) => {
  if (!project) return null;

  const projectLink = project.isProtected 
    ? "/portfolio"
    : project.externalLink || `/projects/${project.slug.current}`;

  return (
    <article className="group relative bg-white dark:bg-gray-900 rounded-none md:rounded-2xl overflow-hidden flex flex-col transition-all duration-300">
      {/* Imagen destacada con link */}
      {project.mainImage && (
        <Link 
          to={projectLink}
          className="relative w-full h-[200px] md:h-[300px] overflow-hidden"
        >
          <img
            src={urlFor(project.mainImage)
              .width(800)
              .height(600)
              .fit('crop')
              .url()}
            alt={project.title}
            className="w-full h-full object-cover transition-all duration-500 
                     grayscale group-hover:grayscale-0 group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-black/0 group-hover:bg-black/10 transition-colors duration-500" />
        </Link>
      )}

      {/* Contenido */}
      <div className="p-4 sm:p-6 md:p-8 flex flex-col flex-grow">
        {project.category && (
          <span className="inline-block text-xs font-mono text-gray-500 dark:text-gray-400 mb-4 md:mb-6">
            {project.category}
          </span>
        )}

        <div className="mb-6 md:mb-8">
          <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-display leading-tight mb-4 md:mb-6 text-gray-900 dark:text-white">
            {project.title}
          </h2>
          {project.description && (
            <p className="text-base md:text-lg text-gray-600 dark:text-gray-300 leading-relaxed">
              {project.description}
            </p>
          )}
        </div>

        <div className="mt-auto font-mono">
          {project.isProtected ? (
            <Link 
              to="/portfolio" 
              className="inline-flex items-center text-gray-900 dark:text-white hover:opacity-70 transition-opacity text-sm group"
              aria-label={"Ver más detalles sobre " + project.title}
            >
              <span className="border-b border-current pb-0.5">
                Solicitar acceso
              </span>
              <svg className="w-4 h-4 ml-2 opacity-70" viewBox="0 0 24 24" fill="none">
                <path d="M12 17a2 2 0 100-4 2 2 0 000 4z" fill="currentColor"/>
                <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM8.9 6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H8.9V6z" fill="currentColor"/>
              </svg>
            </Link>
          ) : project.externalLink ? (
            <a
              href={project.externalLink}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center text-gray-900 dark:text-white hover:opacity-70 transition-opacity text-sm"
              aria-label={"Visitar " + project.title}
            >
              <span className="border-b border-current pb-0.5">
                Visitar sitio →
              </span>
            </a>
          ) : project.slug?.current ? (
            <Link 
              to={"/projects/" + project.slug.current}
              className="inline-flex items-center text-gray-900 dark:text-white hover:opacity-70 transition-opacity text-sm"
              aria-label={"Ver más detalles sobre " + project.title}
            >
              <span className="border-b border-current pb-0.5">
                Ver proyecto →
              </span>
            </Link>
          ) : null}
        </div>
      </div>
    </article>
  );
};

export default ProjectCard;