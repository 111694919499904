// src/components/shared/SupabaseKeepAlive.jsx
import { useEffect, useRef } from 'react';
import { supabase, isSupabaseInitialized } from '@/lib/supabase';

const PING_INTERVAL = 60000 * 360; // 360 minutos (6 horas)

export const SupabaseKeepAlive = () => {
  const pingTimerRef = useRef(null);

  const pingDatabase = async () => {
    if (!isSupabaseInitialized()) {
      console.warn('Cliente Supabase no inicializado');
      return;
    }

    try {
      const { error } = await supabase
        .from('health_checks')
        .select('created_at')
        .limit(1);

      if (error) {
        console.warn('Supabase health check error:', error.message);
      }
    } catch (err) {
      console.error('Supabase ping error:', err);
    }
  };

  useEffect(() => {
    if (!isSupabaseInitialized()) {
      console.warn('KeepAlive desactivado: Cliente Supabase no disponible');
      return;
    }

    // Ejecutar ping inicial con un pequeño delay para dar tiempo a la inicialización
    const initTimeout = setTimeout(pingDatabase, 2000);

    // Configurar intervalo
    pingTimerRef.current = setInterval(pingDatabase, PING_INTERVAL);

    // Cleanup
    return () => {
      clearTimeout(initTimeout);
      if (pingTimerRef.current) {
        clearInterval(pingTimerRef.current);
      }
    };
  }, []); // No necesitamos supabase como dependencia ya que es una instancia global

  return null;
};

export default SupabaseKeepAlive;