// src/components/portfolio/AccessRequestForm.jsx
import React, { useState, useEffect, useRef } from 'react';
import { X } from 'lucide-react';
import { requestPortfolioAccess } from '../../lib/portfolio';
import { toast } from 'sonner';

const AccessRequestForm = ({ onClose, onSuccess }) => {
  const containerRef = useRef(null);
  const widgetRef = useRef(null);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    company: '',
    phone: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [turnstileToken, setTurnstileToken] = useState(null);
  const [isTurnstileLoading, setIsTurnstileLoading] = useState(true);

  useEffect(() => {
    let timeoutId;
    let retryCount = 0;
    const maxRetries = 3;

    const resetWidget = () => {
      if (widgetRef.current && window.turnstile) {
        window.turnstile.remove(widgetRef.current);
        widgetRef.current = null;
      }
    };

    const initializeTurnstile = () => {
      if (window.turnstile && containerRef.current) {
        try {
          resetWidget();

          const widgetOptions = {
            sitekey: import.meta.env.VITE_TURNSTILE_SITE_KEY,
            theme: document.documentElement.classList.contains('dark') ? 'dark' : 'light',
            callback: function(token) {
              setTurnstileToken(token);
              setError('');
            },
            'refresh-expired': 'auto',
            'execution': 'render',
            'appearance': 'always',
            'language': 'es',
            'action': 'portfolio_access'
          };

          // Render del widget
          widgetRef.current = window.turnstile.render(containerRef.current, widgetOptions);
          setIsTurnstileLoading(false);
        } catch (error) {
          console.error('Error initializing Turnstile:', error);
          resetWidget();
          
          if (retryCount < maxRetries) {
            retryCount++;
            timeoutId = setTimeout(initializeTurnstile, 1000);
          } else {
            setError('Error al cargar la verificación de seguridad. Por favor, recarga la página.');
            setIsTurnstileLoading(false);
          }
        }
      } else {
        timeoutId = setTimeout(initializeTurnstile, 1000);
      }
    };

    initializeTurnstile();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      resetWidget();
    };
  }, []);

  const validateForm = () => {
    if (!formData.fullName.trim()) return 'Por favor, ingresa tu nombre completo.';
    if (!formData.email.trim()) return 'Por favor, ingresa un correo electrónico.';
    if (!formData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) return 'El correo que ingresaste no es válido.';
    if (!formData.company.trim()) return 'Tu empresa o lugar de trabajo es requerido';
    if (!formData.phone.trim()) return 'Tu teléfono/WhatsApp es requerido';
    if (!turnstileToken) return 'Por favor, completa la verificación de seguridad.';
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    setLoading(true);

    try {
      const response = await requestPortfolioAccess({
        ...formData,
        token: turnstileToken
      });

      if (response.success) {
        toast.success(response.message || '¡Solicitud enviada! Te hemos enviado un correo con las instrucciones.');
        onSuccess?.();
        onClose();
      }
    } catch (err) {
      setError(err.message || 'Hubo un error al procesar tu solicitud. Por favor, inténtalo nuevamente.');
      if (widgetRef.current && window.turnstile && !err.message.includes('Por favor')) {
        window.turnstile.reset(widgetRef.current);
        setTurnstileToken(null);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (error) setError('');
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="max-w-md w-full bg-white dark:bg-gray-900 rounded-2xl shadow-lg relative animate-fade-in">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-2 text-gray-400 hover:text-black dark:hover:text-white 
                   transition-colors rounded-lg focus:ring-2 focus:ring-black dark:focus:ring-white"
          aria-label="Cerrar formulario"
        >
          <X className="w-5 h-5" />
        </button>

        <div className="p-6 md:p-8">
          <div className="mb-8">
            <h2 className="text-3xl font-display mb-4">Gracias por tu interés</h2>
            <p className="text-gray-600 dark:text-gray-300">
              Completa los datos para solicitar acceso al portafolio.
              Te enviaré un enlace único a tu correo para que puedas acceder.
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label 
                htmlFor="fullName"
                className="block text-sm font-mono text-gray-700 dark:text-gray-300 mb-2"
              >
                Tu nombre completo *
              </label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700
                         focus:ring-2 focus:ring-black dark:focus:ring-white focus:border-transparent
                         bg-white dark:bg-gray-800 text-black dark:text-white"
                placeholder="Ingresa tu nombre completo"
              />
            </div>

            <div>
              <label 
                htmlFor="email"
                className="block text-sm font-mono text-gray-700 dark:text-gray-300 mb-2"
              >
                Un correo electrónico válido *
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700
                         focus:ring-2 focus:ring-black dark:focus:ring-white focus:border-transparent
                         bg-white dark:bg-gray-800 text-black dark:text-white"
                placeholder="tu@email.com"
              />
            </div>

            <div>
              <label 
                htmlFor="company"
                className="block text-sm font-mono text-gray-700 dark:text-gray-300 mb-2"
              >
                Empresa u organización donde trabajas *
              </label>
              <input
                type="text"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleChange}
                className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700
                         focus:ring-2 focus:ring-black dark:focus:ring-white focus:border-transparent
                         bg-white dark:bg-gray-800 text-black dark:text-white"
                placeholder="Nombre de tu empresa"
              />
            </div>

            <div>
              <label 
                htmlFor="phone"
                className="block text-sm font-mono text-gray-700 dark:text-gray-300 mb-2"
              >
                Teléfono o WhatsApp de contacto *
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700
                         focus:ring-2 focus:ring-black dark:focus:ring-white focus:border-transparent
                         bg-white dark:bg-gray-800 text-black dark:text-white"
                placeholder="+52 55 XXXX XXXX"
              />
            </div>

            <div className="space-y-4">
              <div 
                ref={containerRef}
                className="flex justify-center"
                aria-label="Widget de verificación de seguridad"
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
              />
              {isTurnstileLoading && (
                <p className="text-sm text-center text-gray-500 dark:text-gray-400">
                  Cargando verificación de seguridad...
                </p>
              )}
            </div>

            {error && (
              <p className="text-red-500 dark:text-red-400 text-sm font-mono" role="alert">
                {error}
              </p>
            )}

            <button
              type="submit"
              disabled={loading || isTurnstileLoading}
              className="w-full bg-black dark:bg-white text-white dark:text-black py-3 rounded-lg
                       hover:bg-gray-800 dark:hover:bg-gray-100 transition-colors font-mono
                       disabled:opacity-50 disabled:cursor-not-allowed
                       focus:outline-none focus:ring-2 focus:ring-black dark:focus:ring-white"
            >
              {loading ? 'Enviando...' : 'Solicitar acceso'}
            </button>
          </form>

          <p className="mt-6 text-xs text-gray-500 dark:text-gray-400">
            Protegemos tus datos y solo los utilizaremos para procesar tu solicitud de acceso.
            No serán compartidos con terceros.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AccessRequestForm;