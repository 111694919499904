// src/components/shared/ErrorBoundary.jsx
import React, { useState } from 'react';

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  if (hasError) {
    return (
      <div className="min-h-screen pt-20">
        <div className="max-w-3xl mx-auto px-6 py-16">
          <h1 className="text-2xl font-display text-red-600 mb-4">
            Algo salió mal
          </h1>
          <p className="text-gray-600 mb-4">
            Lo siento, algo salió mal. Trata recargando la página, o escríbeme a web@jistark.net
          </p>
          <button
            onClick={() => window.location.reload()}
            className="bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
          >
            Reintentar
          </button>
        </div>
      </div>
    );
  }

  return children;
};

export default ErrorBoundary;