// src/components/blog/BlogList.jsx
import React, { useState, useEffect } from 'react';
import { client } from '../../lib/sanity';
import BlogPreview from './BlogPreview';
import SEO from '../SEO';

const BlogList = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadPosts = async () => {
      try {
        const query = `
          *[_type == "post"] | order(publishedAt desc) {
            _id,
            title,
            permalink,
            publishedAt,
            excerpt,
            mainImage,
            author->{
              name
            },
            categories[]->{
              title
            }
          }
        `;
        const result = await client.fetch(query);
        setPosts(result || []);
      } catch (error) {
        console.error('Error loading blog posts:', error);
        setError('No pude cargar los artículos');
      } finally {
        setLoading(false);
      }
    };

    loadPosts();
  }, []);

  if (loading) {
    return (
      <>
        <SEO title="Textos escogidos" />
        <div className="min-h-screen pt-20">
          <div className="max-w-4xl mx-auto px-6 py-16">
            <div className="animate-pulse">
              <div className="h-12 bg-gray-100 dark:bg-gray-800 rounded w-1/4 mb-6" />
              <div className="h-4 bg-gray-100 dark:bg-gray-800 rounded w-3/4 mb-16" />
              <div className="space-y-16">
                {[...Array(3)].map((_, index) => (
                  <div key={index}>
                    <div className="h-8 bg-gray-100 dark:bg-gray-800 rounded w-3/4 mb-4" />
                    <div className="h-4 bg-gray-100 dark:bg-gray-800 rounded w-1/4 mb-4" />
                    <div className="h-24 bg-gray-100 dark:bg-gray-800 rounded w-full" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <SEO 
          title="Error - Textos escogidos"
          noindex={true}
        />
        <div className="min-h-screen pt-20">
          <div className="max-w-4xl mx-auto px-6 py-16">
            <p className="text-red-600 dark:text-red-400">{error}</p>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <SEO 
        title="Textos escogidos"
        description="He escrito de tecnología de consumo, redes sociales, startups y más desde 2005: me ha tocado ver de todo. Aquí, una pequeña selección."
        type="blog"
      />
      <div className="min-h-screen pt-20">
        <div className="max-w-4xl mx-auto px-6 py-16">
          <header className="mb-16">
            <h1 className="text-4xl md:text-5xl font-display mb-6">Textos escogidos</h1>
            <p className="text-xl text-gray-600 dark:text-gray-300 leading-relaxed max-w-2xl">
              He escrito de tecnología de consumo, redes sociales, startups y más desde 2005: me ha tocado ver de todo. Aquí, una pequeña selección.
            </p>
          </header>
          
          {posts.length === 0 ? (
            <p className="text-gray-600 dark:text-gray-300">Aún no escribo nada, lo que es raro. Si esto pareciera ser un problema, escríbeme a <em>web arroba este dominio</em>.</p>
          ) : (
            <div className="divide-y divide-gray-200 dark:divide-gray-800">
              {posts.map(post => (
                <BlogPreview key={post._id} post={post} />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogList;