// src/components/layout/Navigation.jsx
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import ThemeToggle from '../shared/ThemeToggle';

const Navigation = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const isHome = location.pathname === '/';

  // Manejar el scroll
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setHasScrolled(scrollTop > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const menuItems = [
    { path: '/about', text: 'sobre mi' },
    { path: '/blog', text: 'textos escogidos' },
    { path: '/portfolio', text: 'portafolio' }
  ];

  const shouldShowBackground = !isHome || hasScrolled || isMenuOpen;

  return (
    <nav 
      className={`fixed top-0 left-0 right-0 w-full z-50 transition-all duration-300 ${
        shouldShowBackground
          ? 'bg-white/80 dark:bg-gray-950/80 backdrop-blur-md border-b border-gray-200 dark:border-gray-800' 
          : 'bg-transparent border-transparent'
      }`}
    >
      <div className="max-w-[1400px] mx-auto px-4 sm:px-6 md:px-8">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Logo */}
          <Link 
            to="/" 
            className={`text-base md:text-xl font-display transition-colors ${
              !shouldShowBackground
                ? 'text-black dark:text-white hover:text-gray-800 dark:hover:text-gray-200' 
                : 'text-black dark:text-white'
            }`}
          >
            ji stark
          </Link>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-8">
            {menuItems.map(({ path, text }) => (
              <Link
                key={path}
                to={path}
                className={`relative py-2 text-sm font-mono transition-colors ${
                  location.pathname === path
                    ? 'text-black dark:text-white'
                    : 'text-gray-600 dark:text-gray-300 hover:text-black dark:hover:text-white'
                }`}
              >
                {text}
                {location.pathname === path && (
                  <span className="absolute bottom-0 left-0 w-full h-px bg-current"/>
                )}
              </Link>
            ))}
            <ThemeToggle />
          </div>

          {/* Mobile Menu Button */}
          <div className="flex items-center space-x-4 md:hidden">
            <ThemeToggle />
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className={`p-2 transition-colors ${
                !shouldShowBackground
                  ? 'text-black dark:text-white' 
                  : 'text-gray-600 dark:text-gray-300'
              }`}
              aria-label={isMenuOpen ? 'Cerrar menú' : 'Abrir menú'}
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden py-6 px-4 border-t border-gray-200 dark:border-gray-800 bg-white/95 dark:bg-gray-950/95 backdrop-blur-md">
            <div className="flex flex-col space-y-6">
              {menuItems.map(({ path, text }) => (
                <Link
                  key={path}
                  to={path}
                  className={`text-sm font-mono ${
                    location.pathname === path
                      ? 'text-black dark:text-white'
                      : 'text-gray-600 dark:text-gray-300'
                  }`}
                  onClick={() => setIsMenuOpen(false)}
                >
                  {text}
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navigation;