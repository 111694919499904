// src/components/home/Hero.jsx
import React from 'react';

const Hero = () => {
  return (
    <section className="w-full max-w-[1400px] mx-auto px-4 sm:px-6 md:px-8 
                        flex flex-col justify-center
                        min-h-[60vh] md:min-h-[75vh]
                        pt-16 md:pt-20"> {/* Adjusted padding-top for navigation */}
      <div className="flex flex-col gap-8 md:gap-12">
        <h1 className="text-5xl sm:text-6xl md:text-[80px] lg:text-[100px] xl:text-[140px] 
                       font-display leading-[1.1] sm:leading-[0.95] tracking-tight 
                       max-w-[1200px]">
          Una buena experiencia lo{' '}
          <em className="font-serif not-italic">transforma*</em>{' '}
          todo.
        </h1>
        
        <div className="md:self-end max-w-[300px]">
          <p className="text-base md:text-sm text-gray-500 dark:text-gray-400">
            * Investigo y pienso cómo productos y servicios pueden ser más humanos, 
            significativos y eficientes, basados en la intersección entre tecnología y cultura.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;