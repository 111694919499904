// App.jsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'sonner';
import Analytics from './components/Analytics';
import SEO from './components/SEO';
import SupabaseKeepAlive from './components/shared/SupabaseKeepAlive';
import './styles/blog.css';

// Layout components
import Navigation from './components/layout/Navigation';
import Footer from './components/layout/Footer';
import ErrorBoundary from './components/shared/ErrorBoundary';

// Page components
import Home from './components/home/Home';
import About from './components/About';
import BlogList from './components/blog/BlogList';
import BlogPost from './components/blog/BlogPost';
import PortfolioPage from './components/portfolio/PortfolioPage';
import ProjectDetail from './components/portfolio/ProjectDetail';
import VerifyAccess from './components/portfolio/VerifyAccess';

const NotFound = () => (
  <>
    <SEO 
      title="Página no encontrada"
      description="Lo siento, no encontré lo que buscas."
      noindex={true}
    />
    <div className="min-h-screen pt-20">
      <div className="max-w-3xl mx-auto px-6 py-16">
        <h1 className="text-4xl font-display mb-8">
          Lo siento, no encontré lo que buscas. :(
        </h1>
        <Link 
          to="/" 
          className="inline-flex items-center text-black dark:text-white hover:text-gray-600 dark:hover:text-gray-300 transition-colors"
        >
          <span className="font-mono">←</span>
          <span className="ml-2">Vuelve al inicio</span>
        </Link>
      </div>
    </div>
  </>
);

// Rutas principales de la aplicación
const routes = [
  { path: '/', element: <Home /> },
  { path: '/about', element: <About /> },
  { path: '/blog', element: <BlogList /> },
  { path: '/blog/:permalink', element: <BlogPost /> },
  // Rutas del portafolio
  { path: '/portfolio', element: <PortfolioPage /> },
  { path: '/portfolio/verify/:token', element: <VerifyAccess /> },
  { path: '/projects/:slug', element: <ProjectDetail /> },
  { path: '*', element: <NotFound /> }
];

const App = () => {
  // Función para establecer variables CSS según el tema
  React.useEffect(() => {
    const updateThemeVars = () => {
      const isDark = document.documentElement.classList.contains('dark');
      document.documentElement.style.setProperty(
        '--bg-color',
        isDark ? '#0a0a0a' : '#ffffff'
      );
      document.documentElement.style.setProperty(
        '--text-color',
        isDark ? '#ffffff' : '#000000'
      );
      document.documentElement.style.setProperty(
        '--border-color',
        isDark ? '#27272a' : '#e5e5e5'
      );
    };

    // Observar cambios en el tema
    const observer = new MutationObserver(updateThemeVars);
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class']
    });

    // Establecer valores iniciales
    updateThemeVars();

    return () => observer.disconnect();
  }, []);

  return (
    <ErrorBoundary>
      <HelmetProvider>
        <Router>
          <Analytics />
          <SupabaseKeepAlive />
          <div className="flex flex-col min-h-screen bg-white dark:bg-gray-950 text-black dark:text-white">
            <Navigation />
            <main className="flex-grow">
              <Routes>
                {routes.map(({ path, element }) => (
                  <Route 
                    key={path} 
                    path={path} 
                    element={element} 
                  />
                ))}
              </Routes>
            </main>
            <Footer />
            <Toaster 
              position="bottom-right"
              theme="system"
              toastOptions={{
                className: 'font-mono',
                style: {
                  background: 'var(--bg-color)',
                  color: 'var(--text-color)',
                  border: '1px solid var(--border-color)',
                },
                duration: 5000,
              }}
            />
          </div>
        </Router>
      </HelmetProvider>
    </ErrorBoundary>
  );
};

export default App;