// src/components/portfolio/ProjectDetail.jsx

import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { PortableText } from '@portabletext/react';
import { ArrowLeft, ExternalLink, Lock } from 'lucide-react';
import { client, urlFor } from '../../lib/sanity';
import portableTextComponents from '../shared/PortableTextComponents';
import SEO from '../SEO';

// IMPORTS PARA AUTENTICACIÓN
import { isPortfolioSessionValid } from '../../lib/portfolio';
import PortfolioLogin from './PortfolioLogin';

const ProjectDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Estados para el proyecto y su carga
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Estados para autenticación
  const [showLogin, setShowLogin] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Verificar autenticación al cargar
  useEffect(() => {
    const checkAuth = () => {
      const hasPassword = localStorage.getItem('portfolio-auth') === 'true';
      const hasValidSession = isPortfolioSessionValid();
      setIsAuthenticated(hasPassword || hasValidSession);
    };

    checkAuth();
    // Escucha cambios en sessionStorage por si el usuario se autentica en otra pestaña
    window.addEventListener('storage', checkAuth);
    return () => window.removeEventListener('storage', checkAuth);
  }, []);

  // Manejar scroll al cambiar de slug
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [slug]);

  // Cargar datos del proyecto desde Sanity
  useEffect(() => {
    const fetchProject = async () => {
      try {
        const query = `
          *[_type == "project" && slug.current == $slug][0] {
            title,
            description,
            category,
            mainImage {
              asset-> {
                _id,
                _type,
                url,
                metadata {
                  dimensions
                }
              }
            },
            publishedAt,
            // Asegúrate de tener la propiedad isProtected en tu esquema de Sanity
            isProtected,
            body[] {
              ...,
              asset->,
              children[] {
                ...,
                marks,
                text,
                _type == "span" => @
              }
            },
            seoDescription,
            "estimatedReadingTime": round(length(pt::text(body)) / 5 / 180),
            externalLink
          }
        `;
        const result = await client.fetch(query, { slug });
        if (!result) throw new Error('Proyecto no encontrado');

        // Si el proyecto está protegido y el usuario no está autenticado, mostramos login
        if (result.isProtected && !isAuthenticated) {
          setShowLogin(true);
        }

        setProject(result);
      } catch (err) {
        console.error('Error loading project:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [slug, isAuthenticated]);

  // Pantalla de carga
  if (loading) {
    return (
      <>
        <SEO title="Cargando..." noindex={true} />
        <div className="min-h-screen pt-20">
          <div className="animate-pulse">
            <div className="h-[500px] bg-gray-100 dark:bg-gray-800 w-full mb-12" />
            <div className="max-w-5xl mx-auto px-6">
              <div className="h-12 bg-gray-100 dark:bg-gray-800 rounded w-2/3 mb-8" />
              <div className="space-y-4">
                <div className="h-4 bg-gray-100 dark:bg-gray-800 rounded w-full" />
                <div className="h-4 bg-gray-100 dark:bg-gray-800 rounded w-5/6" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  // Error o proyecto no encontrado
  if (error || !project) {
    return (
      <>
        <SEO title="Proyecto no encontrado" noindex={true} />
        <div className="min-h-screen pt-20">
          <div className="max-w-5xl mx-auto px-6 py-16">
            <h1 className="text-2xl font-display text-red-600 dark:text-red-400 mb-4">
              {error || 'Proyecto no encontrado'}
            </h1>
            <button
              onClick={() => navigate(-1)}
              className="inline-flex items-center text-gray-900 dark:text-white hover:text-gray-600 dark:hover:text-gray-300 transition-colors font-mono text-sm"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              Volver al portafolio
            </button>
          </div>
        </div>
      </>
    );
  }

  // Si el proyecto está protegido y no estamos autenticados, mostrar login
  if (showLogin) {
    return (
      <>
        <SEO
          title={project.title}
          description="Este proyecto está protegido. Por favor, inicia sesión para verlo."
          noindex={true}
        />
        <div className="min-h-screen pt-20">
          <div className="max-w-5xl mx-auto px-6 py-16">
            <div className="mb-12">
              <button
                onClick={() => navigate(-1)}
                className="inline-flex items-center text-gray-900 dark:text-white hover:text-gray-600 dark:hover:text-gray-300 transition-colors font-mono text-sm"
              >
                <ArrowLeft className="w-4 h-4 mr-2" />
                Volver
              </button>
            </div>

            <div className="text-center mb-12">
              <Lock className="w-8 h-8 mx-auto mb-4 text-gray-900 dark:text-gray-100" />
              <h1 className="text-3xl font-display mb-4">Contenido Protegido</h1>
              <p className="text-gray-600 dark:text-gray-300 max-w-lg mx-auto">
                Este proyecto requiere autenticación para ser visualizado.
                Puedes solicitar un enlace de acceso o usar tu contraseña si ya la tienes.
              </p>
            </div>

            <PortfolioLogin
              onSuccess={() => {
                setIsAuthenticated(true);
                setShowLogin(false);
              }}
            />
          </div>
        </div>
      </>
    );
  }

  // Layout original del proyecto (cuando NO está protegido o ya estamos autenticados)
  return (
    <>
      <SEO
        title={project.title}
        description={project.seoDescription || project.description}
        type="article"
        publishedAt={project.publishedAt}
        image={project.mainImage?.asset?.url || null}
      />
      <article className="min-h-screen pt-20">
        <div className="w-full mb-16">
          {project.mainImage?.asset?.url && (
            <div className="w-full h-[400px] md:h-[500px] mb-12 overflow-hidden">
              <img
                src={project.mainImage.asset.url}
                alt={project.title}
                className="w-full h-full object-cover"
              />
            </div>
          )}

          <div className="max-w-5xl mx-auto px-6">
            {project.category && (
              <div className="mb-6">
                <span className="text-xs font-mono text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  {project.category}
                </span>
              </div>
            )}

            <h1 className="text-4xl md:text-5xl lg:text-6xl font-display leading-[1.1] mb-6">
              {project.title}
            </h1>

            <p className="text-xl md:text-2xl text-gray-600 dark:text-gray-300 max-w-3xl">
              {project.description}
            </p>
          </div>
        </div>

        <div className="max-w-5xl mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 border-y border-gray-200 dark:border-gray-800 py-12">
            {project.category && (
              <div>
                <dt className="text-xs font-mono text-gray-500 dark:text-gray-400 uppercase tracking-wider mb-2">
                  Categoría
                </dt>
                <dd className="text-lg text-black dark:text-white">
                  {project.category}
                </dd>
              </div>
            )}
            {project.publishedAt && (
              <div>
                <dt className="text-xs font-mono text-gray-500 dark:text-gray-400 uppercase tracking-wider mb-2">
                  Fecha
                </dt>
                <dd className="text-lg text-black dark:text-white">
                  {new Date(project.publishedAt).toLocaleDateString('es-ES', {
                    year: 'numeric',
                    month: 'long'
                  })}
                </dd>
              </div>
            )}
            {project.estimatedReadingTime && (
              <div>
                <dt className="text-xs font-mono text-gray-500 dark:text-gray-400 uppercase tracking-wider mb-2">
                  Tiempo de lectura
                </dt>
                <dd className="text-lg text-black dark:text-white">
                  {project.estimatedReadingTime} minutos
                </dd>
              </div>
            )}
          </div>

          {/* Contenido principal */}
          {project.body && (
            <div className="max-w-3xl mx-auto py-12 md:py-20">
              <div className="prose lg:prose-lg dark:prose-invert prose-headings:font-display prose-p:text-gray-600 dark:prose-p:text-gray-300 prose-a:text-blue-600 dark:prose-a:text-blue-400 prose-a:no-underline hover:prose-a:underline prose-img:rounded-lg">
                <PortableText
                  value={project.body}
                  components={portableTextComponents}
                  onMissingComponent={(component, options) => {
                    console.warn('Missing component:', component, options);
                    return null;
                  }}
                />
              </div>
            </div>
          )}

          {/* Navegación */}
          <div className="max-w-3xl mx-auto flex justify-between items-center py-8 border-t border-gray-200 dark:border-gray-800">
            <Link
              to="/portfolio"
              className="inline-flex items-center text-gray-900 dark:text-white hover:text-gray-600 dark:hover:text-gray-300 transition-colors font-mono text-sm group"
            >
              <ArrowLeft className="w-4 h-4 mr-2 transform transition-transform group-hover:-translate-x-1" />
              <span>Volver al portafolio</span>
            </Link>
            {project.externalLink && (
              <a
                href={project.externalLink}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center text-gray-900 dark:text-white hover:text-gray-600 dark:hover:text-gray-300 transition-colors font-mono text-sm group"
              >
                <span>Ver proyecto</span>
                <ExternalLink className="w-4 h-4 ml-2 transform transition-transform group-hover:translate-x-1" />
              </a>
            )}
          </div>
        </div>
      </article>
    </>
  );
};

export default ProjectDetail;