// src/components/portfolio/VerifyAccess.jsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import { verifyAccessToken } from '../../lib/portfolio';
import { toast } from 'sonner';

const VerifyAccess = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [verifying, setVerifying] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const verify = async () => {
      if (!token) {
        setError('Token no válido');
        setVerifying(false);
        return;
      }

      try {
        const response = await fetch('/api/verify-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token })
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'Error al verificar el acceso');
        }

        // Guardar sesión
        localStorage.setItem('portfolio-auth', 'true');
        localStorage.setItem('portfolio-session', data.sessionToken);
        
        // Notificar y redirigir
        toast.success('¡Todo bien! 👍 Te doy la bienvenida a mi portafolio.');
        navigate('/portfolio');
      } catch (err) {
        console.error('Error verifying access:', err);
        setError(err.message || 'Error al verificar el acceso');
      } finally {
        setVerifying(false);
      }
    };

    verify();
  }, [token, navigate]);

  if (verifying) {
    return (
      <div className="min-h-screen pt-20">
        <div className="max-w-md mx-auto text-center p-8">
          <Loader2 className="w-8 h-8 mx-auto mb-4 animate-spin text-gray-900 dark:text-white" />
          <h1 className="text-2xl font-display mb-2">Sólo unos segundos más</h1>
          <p className="text-gray-600 dark:text-gray-300">
            Por favor, espera un momento mientras verifico que eres tú…
          </p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen pt-20">
        <div className="max-w-md mx-auto p-8">
          <div className="text-center mb-8">
            <h1 className="text-2xl font-display text-red-600 dark:text-red-400 mb-4">
              Lo siento…
            </h1>
            <p className="text-gray-600 dark:text-gray-300 mb-6">
              {error}
            </p>
            <div className="space-y-4">
              <button
                onClick={() => navigate('/portfolio')}
                className="inline-flex items-center px-4 py-2 text-sm font-mono
                         bg-black dark:bg-white text-white dark:text-black
                         hover:bg-gray-800 dark:hover:bg-gray-100
                         transition-colors rounded-lg"
              >
                Volver al portafolio
              </button>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Si necesitas un nuevo enlace de acceso, {' '}
                <button
                  onClick={() => navigate('/portfolio', { state: { showAccessForm: true } })}
                  className="text-black dark:text-white hover:underline"
                >
                  pídeme uno nuevo acá.
                </button>.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default VerifyAccess;