// src/lib/portfolio.js
import { client } from './sanity';
import { supabase } from './supabase';

/**
 * Obtiene todos los proyectos del portafolio desde Sanity
 */
export async function getPortfolioProjects() {
  const query = `
    *[_type == "project"] | order(featured desc) {
      _id,
      title,
      description,
      category,
      isProtected,
      mainImage,
      slug,
      externalLink,
      featured
    }
  `;
  
  return await client.fetch(query);
}

/**
 * Envía una solicitud de acceso al portafolio
 */
export async function requestPortfolioAccess(formData) {
  try {
    // Usar la URL completa para la solicitud
    const baseUrl = window.location.origin;
    const response = await fetch(`${baseUrl}/api/portfolio-access`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });

    // Intentar parsear la respuesta como JSON
    let data;
    try {
      data = await response.json();
    } catch (e) {
      throw new Error('Error en el servidor. Por favor, intenta más tarde.');
    }

    // Si la respuesta no fue exitosa, lanzar el error con el mensaje del servidor
    if (!response.ok) {
      throw new Error(data.error || 'Error al procesar la solicitud');
    }

    return data;
  } catch (error) {
    console.error('Error en solicitud de acceso:', error);
    
    // Si es un error de red o el servidor no responde
    if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
      throw new Error('No se pudo conectar con el servidor. Por favor, verifica tu conexión.');
    }
    // Propagar el error con un mensaje amigable
    throw new Error(error.message || 'Error al procesar la solicitud. Por favor, intenta más tarde.');
  }
}

/**
 * Verifica la contraseña del portafolio
 */
export function verifyPortfolioAccess(password) {
  return password === import.meta.env.VITE_PORTFOLIO_PASSWORD;
}

/**
 * Verifica un token de acceso
 */
export async function verifyAccessToken(token) {
  try {
    const { data: accessRequest, error: dbError } = await supabase
      .from('portfolio_access_requests')
      .select('*')
      .eq('token', token)
      .eq('status', 'pending')
      .single();

    if (dbError || !accessRequest) {
      throw new Error('El enlace que intentas usar no es válido o ya ha sido utilizado.');
    }

    // Verificar expiración
    if (new Date(accessRequest.expires_at) < new Date()) {
      await supabase
        .from('portfolio_access_requests')
        .update({ status: 'expired' })
        .eq('id', accessRequest.id);

      throw new Error('El enlace ha expirado. Por favor, solicita uno nuevo.');
    }

    // Marcar token como usado
    await supabase
      .from('portfolio_access_requests')
      .update({ 
        status: 'used',
        used_at: new Date()
      })
      .eq('id', accessRequest.id);

    // Crear session token
    const sessionData = {
      id: accessRequest.id,
      email: accessRequest.email,
      name: accessRequest.full_name,
      exp: Math.floor(Date.now() / 1000) + (12 * 60 * 60) // 12 horas
    };

    // Guardar en sessionStorage
    localStorage.setItem('portfolio-auth', 'true');
    localStorage.setItem('portfolio-session', btoa(JSON.stringify(sessionData)));

    return {
      success: true,
      user: {
        name: accessRequest.full_name,
        email: accessRequest.email
      }
    };
  } catch (error) {
    console.error('Error verifying token:', error);
    throw error;
  }
}

/**
 * Verifica si una sesión de portfolio es válida
 */
export function isPortfolioSessionValid() {
  try {
    const sessionToken = localStorage.getItem('portfolio-session');
    if (!sessionToken) return false;

    const session = JSON.parse(atob(sessionToken));
    if (!session.exp) return false;

    return session.exp > Math.floor(Date.now() / 1000);
  } catch (error) {
    console.error('Error checking session:', error);
    return false;
  }
}